import React, {useState, useRef} from 'react';
import {useLocation, useNavigate, Link} from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import '../App.css';
import { Redirect } from 'react-router';
// import AppLayout from "@awsui/components-react/app-layout";
// import FormField from "@awsui/components-react/form-field";
// import Alert from "@awsui/components-react/alert";
// import Container from "@awsui/components-react/container";
// import Header from "@awsui/components-react/header";
// import SideNavigation from '@awsui/components-react/side-navigation';
// import Button from "@awsui/components-react/button";
// import TokenGroup from "@awsui/components-react/token-group";
// import TopNavigation from "@awsui/components-react/top-navigation"
// import SpaceBetween from "@awsui/components-react/space-between";
// import ProgressBar from "@awsui/components-react/progress-bar";
import { Amplify }  from 'aws-amplify'
import {Auth, Storage} from 'aws-amplify';

// import {Authenticator, Text, Image, View, useTheme} from '@aws-amplify/ui-react';
// import HelpPanel from "@awsui/components-react/help-panel";
// import Icon from "@awsui/components-react/icon";
import awsconfig from '../aws-exports';
// import { CognitoUser } from 'amazon-cognito-identity-js';
import { Navigate } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css"
Amplify.configure(awsconfig);

Auth.configure({authenticationFlowType: 'CUSTOM_AUTH'});
var loggedIn = false;
var userName = "";
var userOTP;
var time;


const Login = () => {
    const navigate = useNavigate();
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    
    const [authenticated, setauthenticated] = useState(window.localStorage.getItem(window.localStorage.getItem("authenticated")|| false));
    function handleSubmit() {
            try {
                event.preventDefault();
                Auth.signIn(username, password)
                    .then(user => {
                        userOTP = user;
                        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                            navigate("/resetpassword")
                        }
                        else
                        {
                            userName = username;
                            console.log(user);
                            navigate("/otppage");
                        }
    
                    })
                    .catch(() => {
                        alert("Incorrect UserName or Password!")
                        window.location.reload();
                    });
                // console.log(user);
                // const check = Auth.sendCustomChallengeAnswer(user, 'blue');
                
                // console.log(check);
                console.log(Auth.currentAuthenticatedUser())
            } catch (error) {
                console.log('error signing in', error);
            }
        
    }
    //forgot password page
    function forgotPassword(){
        try{
            var usernameForgot = prompt('Please enter your username.')
            event.preventDefault();
            userName = usernameForgot;
            Auth.forgotPassword(userName)
                .then(user => {
                    userOTP = user;
                    navigate("/forgotpassword")
                })
                .catch(() => {
                    alert("Incorrect UserName or Password!")
                    window.location.reload();
                });
            // console.log(user);
            // const check = Auth.sendCustomChallengeAnswer(user, 'blue');
            
            // console.log(check);
            console.log(Auth.currentAuthenticatedUser())
        } catch (error) {
            console.log('error resetting password in', error);
        }
    }
    return (
        <div className="Auth-form-container" aria-live="polite">
            <form className="Auth-form" onSubmit={handleSubmit}>
                <div className="Auth-form-content" aria-live="polite">
                    <h1 className="Auth-form-title">SIGN INTO PEAS-E</h1>
                    <div className="form-group mt-3" aria-live="polite">
                        <label  title="Username" for="username">User Name</label>
                            <input
                                tabIndex= "1"
                                type="text"
                                title="Username"
                                name ="username"
                                id="username"
                                className="form-control mt-1"
                                placeholder="Enter your user name"
                                onChange={e => setUserName(e.target.value)}
                            />
                    </div>
                    <div className="form-group mt-3" aria-live="polite">
                        <label  title="Password" for="password">Password</label>
                            <input
                                tabIndex= "2"
                                type="password"
                                title = "Password"
                                id = "password"
                                className="form-control mt-1"
                                placeholder="Enter your password"
                                onChange={e => setPassword(e.target.value)}
                            />
                    </div>
                    <div className="d-grid gap-2 mt-3" aria-live="polite">
                        <button type="submit" title="Submit Button" tabIndex= "3" className="btn btn-primary" onClick={() => handleSubmit}>
                        Submit
                        </button>
                        <div className="d-grid gap-2 mt-3" aria-live="polite">
                        <button type ="text" title="Forgot Password" tabIndex= "4" className="btn btn-secondary" onClick={forgotPassword}>
                        Forgot Password
                        </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};
//otppage
const OTPPAGE = () => {
    const navigate = useNavigate();
    const [answer, setAnswer] = useState("");
    const [authenticated, setauthenticated] = useState(window.localStorage.getItem(window.localStorage.getItem("authenticated")|| false));
    var attempts = 0;
    function handleSubmit() {
        try {
            event.preventDefault();
            console.log(userOTP)
            Auth.sendCustomChallengeAnswer(userOTP, answer)
                .then(user => {
                    console.log(user);
                    Auth.currentAuthenticatedUser()
                        .then(user => {
                            setauthenticated(true);
                            loggedIn = true;
                            window.localStorage.setItem("authenticated", true);
                            window.onload = function() {
                                console.log("start")
                                
                            }
                            alert("You are accessing a US Government information system, which includes (1) this computer, (2) this computer network, (3) all computers connected to this network, and (4) all devices and storage media attaached to this network or a computer on this network." +" " +
                                "This information system is for US Government-authorized use only. Unauthorized or improper use of this system may result in disciplinary action, as well as civil and criminal penalties." + " " +
                                "You have no reasonable expectation of privacy regarding any communications or data transmitted to or from, stored on, or transiting this information system." + " "  +
                                "The US Government monitors, intercepts, acquires,searches, and/or seizes all communications and data transmitted to or from, stored on, or transitng this information system, and will retain, and peridically review,use, and disclose such communicatiosn or data for security, intelligence, and other lawful purposes. The communications and data subject to the activites described in this paragraph include the content of: personal communications, messages, emails, website browsing, and any other online account or service sent to this information system, even if you never request, access, or view such content on this information system. Many web applications (e.g., chat, messaging, email, social media, websites, and search engines) send more content to this information system than you actually access or view. For example, accessing your personal email accounton this information system mayexpose the content of unopened, unread personal email and attachements to the US Government, even if you do not purposefully access or view that content on this system. Your entire personal email account, including draft, sent, received, and deleted emails, and contact lists,calendars,etc., may be sent by a service provider to this information system. All of this information would be subject to collection, use, retention, and review." + " "  +
                                "By using this system you acknowledge that you understand and consent to all of the above."
                                )
                            navigate("/content", {state:{authLoggedIn:loggedIn, authUserName:userName, authenticated}});
                            console.log("success logon")
                        })
                })
                .catch(() => {
                    attempts++;
                    console.log(attempts);
                    if (attempts >=3) {
                        alert("Max Attempts Reached! Please Log Back In.")
                        userOTP = null;
                        userName = "";
                        navigate("/login")
                    }
                    else{
                        alert("Incorrect OTP! Please try again.")
                    }
                });
            
            
                    
            // console.log(user);
            // const check = Auth.sendCustomChallengeAnswer(user, 'blue');
            
            // console.log(check);
            console.log(Auth.currentAuthenticatedUser())
        } catch (error) {
            console.log('error signing in', error);
            navigate("/login");
        }
    }
    if (userOTP != null){
        return (
            <div className="Auth-form-container" aria-live="polite">
                <form className="Auth-form" onSubmit={handleSubmit}>
                    <div className="Auth-form-content" aria-live="polite">
                        <h1 className="Auth-form-title">Enter OTP</h1>
                        <div className="form-group mt-3" aria-live="polite">
                            <label>Check your email for the code from</label>
                          <div className="form-group mt-2" aria-live="polite">
                            <label>no-reply@goldcherrygumdrop.com</label>
                                </div>
                                <input
                                    tabIndex= "1"
                                    type="text"
                                    title="OTP"
                                    name ="One Time Password"
                                    id="One Time Password"
                                    className="form-control mt-1"
                                    placeholder="Enter the OTP code recieved from email."
                                    onChange={e => setAnswer(e.target.value)}
                                />
                                </div>
                            <div className="form-group mt-3" aria-live="polite"> 
                            <label> You should be recieving an email within 5 minutes. </label>
                            </div>
                        <div className="d-grid gap-2 mt-3" aria-live="polite">
                       
                        
                            <button type="submit"  title = "submit" tabIndex="2" className="btn btn-primary" onClick={() => handleSubmit}>
                            Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
    else {
        return <Navigate replace to="/login" />;
    }
    
};
//reset password page
const ResetPassword = () => {
    const navigate = useNavigate();
    const [newPassword, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    function handleSubmit() {
        try {
            event.preventDefault();
            console.log(userOTP)
            var re = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")
            if (newPassword ==confirmPassword){
                if (re.test(newPassword) && newPassword.length > 11){
                    Auth.completeNewPassword(
                        userOTP,               // the Cognito User Object
                        newPassword,        // the new password
                    ).then(user => {
                        alert("Please log in with your new password.")
                        userOTP = null;
                        userName = "";
                        navigate("/login")
                    }).catch(e => {
                        alert("Password change failed, please try a different password.")
                    })

                }
                else{
                    alert("Password must be at least 12 characters in length, contain an UPPERCASE, lowercase, Number, and special character.")
                }
            }
            else{
                alert("Passwords do not match.")
            }
        } catch (error) {
            console.log('error signing in', error);
            navigate("/login");
        }
    }
    if (userOTP != null)
    {
        return (
            <div className="Auth-form-container" aria-live="polite">
                <form className="Auth-form" onSubmit={handleSubmit}>
                    <div className="Auth-form-content" aria-live="polite">
                        <h1 className="Auth-form-title">Enter New Password</h1>
                        <div className="form-group mt-3" aria-live="polite">
                            <label>Please Enter a New Password</label>
                                <input
                                    tabIndex= "1"
                                    type="password"
                                    title="Enter New Password"
                                    name ="Enter New Password"
                                    id="Enter New Password"
                                    className="form-control mt-1"
                                    placeholder="Please Enter a new Password"
                                    onChange={e => setPassword(e.target.value)}
                                /></div>
                                <div className="form-group mt-3" aria-live="polite">
                                <input
                                    tabIndex="2"
                                    title="Confirm Password"
                                    name ="Confirm Password"
                                    id="Confirm Password"
                                    type="password"
                                    className="form-control mt-1"
                                    placeholder="Please confirm your new Password"
                                    onChange={e => setConfirmPassword(e.target.value)}
                                />
                                </div>
                                <div className="form-group mt-3" aria-live="polite">
                                 <label> *Password must be at least 12 characters in length, and must contain an UPPERCASE, lowercase, Number, and a Special Character.</label>
                                 </div>
                        
                                    <div className="d-grid gap-2 mt-3" aria-live="polite">
                                    <button type="submit"  title= "submit"tabIndex="3" className="btn btn-primary" onClick={() => handleSubmit}>
                                     Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }    else {
        return <Navigate replace to="/login" />;
    }
};
//forgot password page
const ForgotPassword = () => {
    const navigate = useNavigate();
    const [newPassword, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [code, setCode] = useState("");
    function handleSubmit() {
        try {
            event.preventDefault();
            console.log(userName)
            if (newPassword ==confirmPassword){
                var re = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")
                if (re.test(newPassword) && newPassword.length > 11){
                    Auth.forgotPasswordSubmit(
                        userName,               // the Cognito User Object
                        code,
                        newPassword,        // the new password
                    ).then(user => {
                        alert("Please log in with your new password.")
                        console.log(newPassword)
                        userOTP = null;
                        userName = "";
                        navigate("/login")
                    }).catch(e => {
                        alert("Password change failed, please try a different password.")
                    })

                }else{
                    alert("Password must be at least 12 characters in length, contain an UPPERCASE, lowercase, Number, and special character.")
                }
            }
            else{
                alert("Passwords do not match.")
            }
        } catch (error) {
            console.log('error signing in', error);
            navigate("/login");
        }
    }
    if (userName != "")
    {
        return (
            <div className="Auth-form-container" aria-live="polite">
                <form className="Auth-form" onSubmit={handleSubmit}>
                    <div className="Auth-form-content" aria-live="polite">
                        <h1 className="Auth-form-title">Forgot Password</h1>
                        <div className="form-group mt-3" aria-live="polite">
                            <label>Please Enter the Code Emailed to You:</label>
                                <input
                                    tabIndex="1"
                                    type="text"
                                    title="Temporary Code"
                                    name ="Temporary Code"
                                    id="Temporary Code"
                                    className="form-control mt-1"
                                    placeholder="Enter the code emailed to you."
                                    onChange={e => setCode(e.target.value)}
                                /></div>
                                <div className="form-group mt-3" aria-live="polite">
                                 <label>Please Enter a New Password:</label>
                                <input
                                    tabIndex="2"
                                    type="password"
                                    title="New Password"
                                    name ="New Password"
                                    id="New Password"
                                    className="form-control mt-1"
                                    placeholder="Enter New Password"
                                    onChange={e => setPassword(e.target.value)}
                                />
                                </div>
                                <div className="form-group mt-3" aria-live="polite">
                                 <label>Please Confirm the New Password:</label>
                                <input
                                    tabIndex="3"
                                    type="password"
                                    title="Confirm New Password"
                                    name ="Confirm New Password"
                                    id="Confirm New Password"
                                    className="form-control mt-1"
                                    placeholder="Confirm New Password"
                                    onChange={e => setConfirmPassword(e.target.value)}
                                />
                                </div>
                        <div className="form-group mt-3" aria-live="polite">
                                 <label for="password" title="Password must be at least 12 characters in length, and must contain an UPPERCASE, lowercase, Number, and a Special Character"> Password must be at least 12 characters in length, and must contain an UPPERCASE, lowercase, Number, and a Special Character.</label>
                                 </div>
                        <div className="d-grid gap-2 mt-3" aria-live="polite">
                            <button type="submit"  tabIndex= "4" title="submit" className="btn btn-primary" onClick={() => handleSubmit}>
                            Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
    else {
        return <Navigate replace to="/login" />;
    }
};

export{Login, ForgotPassword, OTPPAGE,ResetPassword};